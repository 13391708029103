import React, { Component } from 'react'
import Img from 'gatsby-image'
import styled from 'styled-components'

import theme from '../styles/theme'

const StaffImg = styled(Img)`
  border-radius: 50%;
  overflow: hidden;
  width: 50%;
  margin-bottom: 20px;
`;

const StaffDiv = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  height: calc(100% - 30px);
`;

export default class StaffMember extends Component {
  render() {
    return (
      <div className={`col-xs-12 col-sm-12 col-md-${this.props.columnWidth} mobi-mb-30 tab-mb-50 col-centered`}>
        <StaffDiv className="team-member white-bg mb-30">
          {this.props.data.photo &&
            <StaffImg fluid={this.props.data.photo.fluid}  />
          }
          <h3 className="mb-5">{this.props.data.name}</h3>
          <h6 className="mb-15">{this.props.data.title}</h6>
          {this.props.data.bio && <div dangerouslySetInnerHTML={{
            __html: this.props.data.bio.childMarkdownRemark.html,
          }}/>}
          {/* <div className="social-icon style1 text-center mt-20">
            <ul className="clearfix">
              <li><a href="https://www.facebook.com/" target="_blank"><i className="zmdi zmdi-facebook" /></a></li>
              <li><a href="https://twitter.com/" target="_blank"><i className="zmdi zmdi-twitter" /></a></li>
              <li><a href="https://instagram.com/" target="_blank"><i className="zmdi zmdi-instagram" /></a></li>
              <li><a href="https://www.pinterest.com/" target="_blank"><i className="zmdi zmdi-pinterest" /></a></li>
            </ul>
          </div> */}
        </StaffDiv>
      </div>
    )
  }
}
