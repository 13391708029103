import React from 'react'
import Img from 'gatsby-image'
import styled from 'styled-components'
import { StaticQuery, graphql, Link } from "gatsby"

import theme from '../styles/theme'

const TestimonialBlock = props => (
    <StaticQuery
    query={graphql`
      query TestimonialQuery {
        allContentfulTestimonials {
          edges {
            node {
              id
              clientName
              companyName
              testimonial {
                childMarkdownRemark {
                  html
                }
              }
            }
          }
        }
      }
    `}
    render={data => {
      return (
        <>
        {data.allContentfulTestimonials.edges.map(({node: testimonial}) => {
          if(testimonial.id === props.id){
            return (
              <div key={testimonial.id} className="testimonial-area light-bg pb-80 section-ptb">
                <div className="container">
                  <div className="row text-center">
                    <div className="col-xs-12 col-md-10 col-text-center">
                      <div className="testimonial-item style1">
                        <div className="client-say">
                          <div className="testyText" dangerouslySetInnerHTML={{
                            __html: testimonial.testimonial.childMarkdownRemark.html
                          }}/>
                          <div className="title-box white-bg">
                            <h4>{testimonial.clientName}</h4>
                            <p className="theme-color">{testimonial.companyName}</p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            )
          }
        })}
        </>
      )}}
  />
  )

export default TestimonialBlock
