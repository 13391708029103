import React from 'react'
import styled from 'styled-components'
import Img from 'gatsby-image'
import { StaticQuery, graphql, Link } from 'gatsby'
import Equalizer from 'react-equalizer'

import theme from '../styles/theme'
import StaffMember from './StaffMember'
import AnchorLink from '../components/AnchorLink'
import { media } from '../styles/utils'

const PhotoGrid = styled.div`
  display: grid;
  height: 100vh;
  grid-template-columns: 1fr 1fr;
  grid-template-rows: 1fr 1fr;
  grid-gap: 10px;

  ${media.medium`
    grid-template-columns: 1fr;
  `}

  div {
  }

  & > div:nth-child(1) {
    grid-column: span 2;

    ${media.medium`
      grid-column: span 1;
    `}
  }
`

const TeamBlock = (props) => (
  <StaticQuery
    query={graphql`
      query StaffQuery {
        allContentfulStaff {
          edges {
            node {
              id
              name
              title
              bio {
                childMarkdownRemark {
                  html
                }
              }
              photo {
                title
                fluid(maxWidth: 1800) {
                  ...GatsbyContentfulFluid_noBase64
                }
              }
            }
          }
        }
      }
    `}
    render={(data) => {
      const numOfItems = props.data.staffMembers.length
      const maxItemsInRow = 3
      const numOfRows = Math.ceil(numOfItems / maxItemsInRow)
      const itemsInEachRow = Math.ceil(numOfItems / numOfRows)
      const columnWidth = 12 / itemsInEachRow
      const potentialMax = maxItemsInRow * numOfRows
      const remainder = itemsInEachRow - (potentialMax - numOfItems)
      const lastRowColumnWidth = 12 / Math.ceil(remainder)
      const startLastRow = (numOfRows - 1) * itemsInEachRow
      const fillLastRow = false

      return (
        <>
          <AnchorLink
            link={
              props.data.staffHeadingNormalColor +
              ' ' +
              props.data.staffHeadingAccentColor
            }
          />
          <div className="team-area section-ptb white-bg">
            <div className="container">
              <div className="row">
                <div className="col-xs-12 text-center">
                  <div className="section-title pb-80">
                    <h2 className="mb-15">
                      {props.data.staffHeadingNormalColor}{' '}
                      <span className="theme-color">
                        {props.data.staffHeadingAccentColor}
                      </span>
                    </h2>
                    <div
                      dangerouslySetInnerHTML={{
                        __html:
                          props.data.staffSubHeading.childMarkdownRemark.html,
                      }}
                    />
                  </div>
                </div>
              </div>
              <div>
                <div className="row text-center mb-60">
                  <Equalizer>
                    {props.data.staffMembers.map((staff, index) => {
                      return index < startLastRow ||
                        startLastRow <= 1 ||
                        fillLastRow === false ? (
                        <StaffMember
                          key={staff.id + index}
                          data={staff}
                          columnWidth={columnWidth}
                        />
                      ) : (
                        <StaffMember
                          key={staff.id + index}
                          data={staff}
                          columnWidth={lastRowColumnWidth}
                        />
                      )
                    })}
                  </Equalizer>
                </div>
              </div>
              <div className="row">
                <div className="col-xs-12 text-center">
                  <h2 className="mb-80">
                    We work hard &{' '}
                    <span className="theme-color">We Have Fun</span>
                  </h2>
                  {props.data.staffImages && (
                    <PhotoGrid>
                      {props.data.staffImages.map((image, index) => {
                        return <Img key={image.id} fluid={image.fluid} />
                      })}
                    </PhotoGrid>
                  )}
                </div>
              </div>
            </div>
          </div>
        </>
      )
    }}
  />
)

export default TeamBlock
