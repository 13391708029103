import React from 'react'
import Img from 'gatsby-image'
import styled from 'styled-components'
import Equalizer from "react-equalizer";
import { StaticQuery, graphql, Link } from "gatsby"

import theme from '../styles/theme'
import AnchorLink from "../components/AnchorLink";

const ServicesBlock = props => (
    <StaticQuery
    query={graphql`
      query ServicesQueryAll {
        contentfulServicesBlock {
          headlineAccentColor
          headlineWhite
          subHeading
          buttonText
          services {
            id
            serviceName
            icon
            description {
              childMarkdownRemark {
                html
              }
            }
          }
        }
      }
    `}
    render={data => {
      let servicesInRow = data.contentfulServicesBlock.services.length
      servicesInRow =  12 / servicesInRow

      const bg = props.bg ? props.bg : "light-bg";
      
      return (
      <div className={`service-area style1 section-ptb ${bg}`}>
      <AnchorLink link={data.contentfulServicesBlock.headlineAccentColor + " " + data.contentfulServicesBlock.headlineWhite} />
      <div className="container">
        <div className="row">
          <div className="col-xs-12 text-center">
            <div className="section-title pb-80">
              
              <h2 className="mb-15">
                <span className="theme-color">{data.contentfulServicesBlock.headlineAccentColor}</span> {data.contentfulServicesBlock.headlineWhite}
              </h2>
              <p>{data.contentfulServicesBlock.subHeading}</p>
            </div>
          </div>
        </div>
        <div className="row mb-30">
          <Equalizer>
          {data.contentfulServicesBlock.services.map((service) => {

            return(
              <div key={service.id} className={`col-xs-12 col-sm-12 col-md-4 mobi-mb-30 mb-30`}>
                <div className="service-box light-bg team-member">
                  <i className={`fas fa-${service.icon}`} /> 
                  <h4>{service.serviceName}</h4>
                  <div dangerouslySetInnerHTML={{
                    __html: service.description.childMarkdownRemark.html,
                  }}/>
                </div>
              </div>
             )})}

          </Equalizer>
        </div>
      </div>
    </div>
    )}}
  />
  )

export default ServicesBlock
