import React from 'react'
import { graphql, Link } from 'gatsby'
import Img from 'gatsby-image'

import Layout from '../components/Layout'
import HeroHalfImageBg from '../components/HeroHalfImageBg'
import HeroComplex from '../components/HeroComplex'
import Container from '../components/Container'
import SEO from '../components/SEO'
import TopProjectsBlock from '../components/TopProjectsBlock'
import ServicesBlock from '../components/ServicesBlockAll'
import PartnershipsBlock from '../components/PartnershipsBlock'
import TeamBlock from '../components/TeamBlock'
import TestimonialBlock from '../components/TestimonialBlock'
import BannerSimple from '../components/BannerSimple'
import theme from '../styles/theme'

const Index = ({ data }) => {
  const {
    hero,
    seo,
    simpleBanner,
    mainContent,
    mainImage,
    mainContentButtonText,
    mainContentButtonUrl,
    testimonial,
  } = data.contentfulAboutServicesPage

  return (
    <Layout>
      {seo && <SEO pagePath="about" postNode={seo} pageSEO />}
      {/* <SEO /> */}
      <Container>
        {hero.internal.type === 'ContentfulHeroSplit' ? (
          <HeroHalfImageBg id={hero.id} />
        ) : (
          ''
        )}
        {hero.internal.type === 'ContentfulHeroComplex' ? (
          <HeroComplex id={hero.id} />
        ) : (
          ''
        )}

        <div className="hero-block style2 section-ptb light-bg">
          <div className="container">
            <div className="row">
              <div className="col-xs-12 col-sm-10 col-md-6">
                <div className="hero-img">
                  <Img fluid={mainImage.fluid} />
                </div>
              </div>
              <div className="col-xs-12 col-sm-10 col-md-6">
                <div className="hero-text mt-20">
                  <div
                    dangerouslySetInnerHTML={{
                      __html: mainContent.childMarkdownRemark.html,
                    }}
                  />
                  <Link
                    className="btn theme-btn mt-25"
                    to={mainContentButtonUrl}
                  >
                    {mainContentButtonText}
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </div>

        <TeamBlock data={data.contentfulAboutServicesPage} />
        <TestimonialBlock id={testimonial.id} />
        <ServicesBlock bg="bg-color-2" />
        <TopProjectsBlock bg="light-bg" projectsBG={data.projectsBG} />
        <a id="partnerships" />
        {/* <PartnershipsBlock bg="white-bg" /> */}
        <BannerSimple id={simpleBanner.id} />
      </Container>
    </Layout>
  )
}

export const query = graphql`
  query {
    contentfulAboutServicesPage {
      hero {
        id
        internal {
          type
        }
      }
      seo {
        pageTitle
        shareImage {
          ogimg: resize(width: 1800) {
            src
            width
            height
          }
        }
        metaDescription {
          metaDescription
        }
        metaKeywords {
          metaKeywords
        }
      }
      simpleBanner {
        id
      }
      mainContent {
        childMarkdownRemark {
          html
        }
      }
      mainImage {
        title
        fluid(maxWidth: 1800) {
          ...GatsbyContentfulFluid_noBase64
        }
      }
      mainContentButtonText
      mainContentButtonUrl
      testimonial {
        id
      }
      staffHeadingNormalColor
      staffHeadingAccentColor
      staffSubHeading {
        childMarkdownRemark {
          html
        }
      }
      staffImages {
        title
        id
        fluid(maxWidth: 1800) {
          ...GatsbyContentfulFluid_noBase64
        }
      }
      staffMembers {
        id
        name
        title
        bio {
          childMarkdownRemark {
            html
          }
        }
        photo {
          title
          fluid(maxWidth: 1800) {
            ...GatsbyContentfulFluid_noBase64
          }
        }
      }
    }
    projectsBG: imageSharp(
      original: { src: { regex: "/abstract-bg-flipped/" } }
    ) {
      fluid(maxWidth: 1200) {
        ...GatsbyImageSharpFluid
      }
    }
  }
`

export default Index
